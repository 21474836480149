<template>
  <div
    class="app-header__container container-fluid"
  >
    <div style="padding: 0 15px;">
      <router-link :to="{ name: 'HomePage' }">
        <Logo :size="30" />
      </router-link>
    </div>

    <b-navbar class="app-header">
      <b-navbar-nav class="w-100">
        <div class="d-flex align-items-center">
          <b-breadcrumb>
            <b-breadcrumb-item
              v-for="item in breadcrumbs"
              :key="item.text"
            >
              {{ item.text }}
            </b-breadcrumb-item>
          </b-breadcrumb>
          <b-dropdown
            v-if="selectedService === 'Электронные письма'"
            class="ml-1 service-dropdown"
            text="Шаблоны писем"
            variant="primary"
          >
            <b-dropdown-item :to="{ name: 'TemplatesPage' }">
              Шаблоны писем
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'EmailsPage' }">
              Письма
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <b-nav-item-dropdown
          class="app-header__menu"
          right
        >
          <template #button-content>
            <b-avatar style="background: transparent;">
              <person-icon :size="24" />
            </b-avatar>
          </template>

          <b-dropdown-text>
            <span class="font-weight-bold">{{ userName }}</span>
          </b-dropdown-text>
          <b-dropdown-text>{{ userEmail }}</b-dropdown-text>

          <b-dropdown-divider />

          <b-dropdown-item
            v-for="resource in availableResources"
            :key="resource.name"
            :to="{ name: resource.component }"
          >
            {{ resource.text }}
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item @click="logout">
            Выйти
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  Logo,
  PersonIcon,
} from '@/components/icons';

export default {
  name: 'AppHeader',
  components: {
    Logo,
    PersonIcon,
  },
  data() {
    return {
      allResources: [
        {
          component: 'ClientsPage',
          name: 'clients',
          text: 'Сведения о клиентах',
        },
        {
          component: 'RequestsPage',
          name: 'requests',
          text: 'Заявки клиентов',
        },
        {
          component: 'TemplatesPage',
          name: 'emails',
          text: 'Электронные письма',
        },
        {
          component: 'MessagesPage',
          name: 'messages',
          text: 'Сообщения',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', [
      'roles',
      'userEmail',
      'userName',
    ]),

    availableResources() {
      return this.allResources.filter(({ name }) => {
        return Boolean(
          this.roles.find(role => {
            const [resource] = role.split('-');
            return resource === name;
          }),
        );
      });
    },

    breadcrumbs() {
      if (!this.selectedService) {
        return [{ text: 'Synergy SVC' }];
      }

      return [
        { text: 'Synergy SVC' },
        { text: this.selectedService },
        { text: '' },
      ];
    },

    selectedService() {
      const matched = this.allResources.find(({ name }) => {
        return this.$route.matched[0]?.path === '/' + name;
      });
      return matched?.text;
    },
  },
  methods: {
    logout() {
      this.$keycloak.logoutFn && this.$keycloak.logoutFn();
    },
  },
};
</script>

<style lang="scss">
.app-header {
  background-color: #333;
  color: white;
  height: 40px;
  width: 100%;

  &__container {
    align-items: center;
    background-color: white;
    display: flex;
    left: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9;
  }

  &__menu {
    margin-left: auto;

    .dropdown {
      &-toggle {
        padding: 0 !important;
      }
    }

    .b-avatar {
      width: 24px;
    }
  }

  .dropdown {
    &-toggle {
      color: inherit !important;

      &:after {
        transform: translateY(-2px);
        vertical-align: 0;
      }

      &:focus-visible {
        outline: none !important;
      }
    }

    &.show {
      .dropdown-toggle:after {
        transform: rotate(180deg) translateY(2px);
      }
    }
  }

  .breadcrumb {
    align-self: center;
    background: transparent;
    display: none;
    font-weight: bold;
    margin: 0;
    padding: 0;

    @media (min-width: 640px) {
      display: flex;
    }

    &-item {
      color: white !important;
      cursor: default;

      & + .breadcrumb-item::before {
        color: #0088ff;
      }

      a {
        color: inherit !important;
        pointer-events: none;
      }
    }
  }

  .service-dropdown {
    .dropdown-toggle {
      line-height: 1;
    }
  }
}
</style>
