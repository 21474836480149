<template>
  <div class="filter-panel">
    <b-container fluid="xl">
      <b-row>
        <b-col>
          <div class="filter-panel__controls">
            <b-button
              size="sm"
              variant="light"
              @click="$emit('close')"
            >
              Закрыть
            </b-button>

            <b-button
              class="ml-auto"
              size="sm"
              variant="light"
              @click="$emit('clear')"
            >
              Очистить
            </b-button>
            <b-button
              class="ml-2"
              size="sm"
              variant="warning"
              @click="$emit('submit')"
            >
              Применить
            </b-button>
          </div>

          <div class="filter-panel__content">
            <slot name="content" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'FilterPanel',
};
</script>

<style lang="scss">
  .filter-panel {
    background-color: #333;
    color: #fff;
    min-height: calc(100vh - 40px);
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;

    @media (min-width: 640px) {
      max-width: 320px;
      min-width: 320px;
      position: relative;
      width: 320px;
    }

    @media (min-width: 1000px) {
      max-width: 480px;
      min-width: 480px;
      position: relative;
      width: 480px;
    }

    &__controls {
      align-items: center;
      display: flex;
      padding: 1rem 0;
      width: 100%;
    }

    .form-control {
      color: #0088FF;
    }
  }
</style>
