import getState from './get_state';
import {
  AuthActionTypes,
  actions,
} from './actions';
import {
  AuthMutationTypes,
  mutations,
} from './mutations';
import getters from './getters';

export const authModule = {
  namespaced: true,
  state: getState(),
  actions,
  mutations,
  getters,
};

export {
  AuthActionTypes,
  AuthMutationTypes,
};
