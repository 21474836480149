<template>
  <div id="app">
    <AppHeader />

    <router-view />
  </div>
</template>

<script>
import { AppHeader } from '@/components/layout';
export default {
  name: 'App',
  components: {
    AppHeader,
  },
  mounted() {
    if (process.env.NODE_ENV !== 'development' && this.$route !== 'HomePage') {
      this.$router.push({ name: 'HomePage' });
    }
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";

#app {
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  padding-top: 40px;
}
</style>
