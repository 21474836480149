import axios from 'axios';
import { MAX_LIMIT } from '@/constants';

export async function getEmailTemplates({ page }) {
  const axiosInstance = axios.create();
  const { data: { data } } = await axiosInstance.get('/emails/templates', {
    params: {
      'page[limit]': MAX_LIMIT,
      'page[offset]': page * MAX_LIMIT,
    },
  });
  return data;
}
