import {
  TemplateActionTypes,
  templatesModule,
} from './templates';

export const emailsModule = {
  namespaced: true,
  modules: {
    templates: templatesModule,
  },
};

export {
  TemplateActionTypes,
};
