export default {
  isAuthenticated({ authenticated }) {
    return authenticated;
  },
  roles({ roles }) {
    return roles;
  },
  userEmail({ tokenParsed }) {
    return tokenParsed?.email ?? '';
  },
  userName({ tokenParsed }) {
    return tokenParsed?.name ?? '';
  },
};
