import { getEmailTemplates } from '@/api/emails';
import { MAX_LIMIT } from '@/constants';

export const TemplateActionTypes = {
  GET_TEMPLATES: 'GET_TEMPLATES',
  RESET_TEMPLATES: 'RESET_TEMPLATES', // Если пользователь возвращается наверх списка нажатием кнопки, необходимо удалять из списка лишние строки
  SET_LOADING: 'SET_LOADING',
  SET_HAS_MORE_ITEMS: 'SET_HAS_MORE_ITEMS',
};

export const TemplateMutationTypes = {
  SET_TEMPLATES: 'SET_TEMPLATES',
  SET_MORE_TEMPLATES: 'SET_MORE_TEMPLATES',
  SET_LOADING: 'SET_LOADING',
  SET_HAS_MORE_ITEMS: 'SET_HAS_MORE_ITEMS',
};

export const templatesModule = {
  namespaced: true,
  state: {
    hasMoreItems: true,
    loading: true,
    templates: null,
  },
  actions: {
    async [TemplateActionTypes.GET_TEMPLATES]({ commit }, { page }) {
      const templates = await getEmailTemplates({ page });
      const mutation = page === 0
        ? TemplateMutationTypes.SET_TEMPLATES
        : TemplateMutationTypes.SET_MORE_TEMPLATES;
      commit(mutation, templates);

      if (!templates.length || templates.length < MAX_LIMIT) {
        commit(TemplateMutationTypes.SET_HAS_MORE_ITEMS, false);
      }
    },

    [TemplateActionTypes.RESET_TEMPLATES]({ commit, getters: { templates } }) {
      commit(
        TemplateMutationTypes.SET_TEMPLATES,
        JSON.parse(JSON.stringify(templates)).splice(0, MAX_LIMIT),
      );
      commit(TemplateMutationTypes.SET_HAS_MORE_ITEMS, true);
    },

    [TemplateActionTypes.SET_LOADING]({ commit }, loading) {
      commit(TemplateMutationTypes.SET_LOADING, loading);
    },
  },
  mutations: {
    [TemplateMutationTypes.SET_TEMPLATES](state, templates) {
      state.templates = templates;

      if (state.loading) {
        state.loading = false;
      }
    },

    [TemplateMutationTypes.SET_MORE_TEMPLATES](state, templates) {
      state.templates = [
        ...state.templates,
        ...templates,
      ];
    },

    [TemplateMutationTypes.SET_LOADING](state, loading) {
      state.loading = loading;
    },

    [TemplateMutationTypes.SET_HAS_MORE_ITEMS](state, hasMoreItems) {
      state.hasMoreItems = hasMoreItems;
    },
  },
  getters: {
    hasMoreItems: state => state.hasMoreItems,
    loading: state => state.loading,
    templates: state => state.templates,
  },
};
