import Vue from 'vue';
import Vuex from 'vuex';
import {
  AuthActionTypes,
  authModule,
} from './auth';
import {
  TemplateActionTypes,
  emailsModule,
} from './emails';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth: authModule,
    emails: emailsModule,
  },
});

export {
  store,
  AuthActionTypes,
  TemplateActionTypes,
};
