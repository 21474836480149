export const API_STATUS = {
  IDLE: Symbol('IDLE'),
  PENDING: Symbol('PENDING'),
  SUCCESS: Symbol('SUCCESS'),
  ERROR: Symbol('ERROR'),
};

export const MAX_LIMIT = 100;

export const SORTING_OPTIONS = {
  CREATED_DESC: 'CREATED_DESC',
  CREATED_ASC: 'CREATED_ASC',
  NAME_DESC: 'NAME_DESC',
  NAME_ASC: 'NAME_ASC',
};
