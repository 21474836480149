import axios from 'axios';

const TOKEN_MIN_VALIDITY = 70;
const INTERVAL = 60000;

function updateHeaders(token) {
  if (token) {
    axios.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default function(keycloak) {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  updateHeaders(keycloak.token);

  // обновление токена
  setInterval(async () => {
    try {
      const refreshed = await keycloak.updateToken(TOKEN_MIN_VALIDITY);

      if (refreshed) {
        console.log('Token refreshed');
        updateHeaders(keycloak.token);
      } else {
        const valid = Math.round(
          keycloak.tokenParsed.exp +
          keycloak.timeSkew -
          new Date().getTime() / 1000,
        );
        console.log(`Token not refreshed, valid for ${valid} seconds`);
      }
    } catch (error) {
      console.log('Failed to refresh token', error);
    }
  }, INTERVAL);
}
