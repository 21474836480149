
export const AuthMutationTypes = {
  INIT: 'INIT',
};

const service = process.env.VUE_APP_SSO_CLIENT_SERVICE;

export const mutations = {
  [AuthMutationTypes.INIT](state, payload) {
    if (!payload.resourceAccess?.[service]) {
      window.location.assign('https://my.synergy.ru');
      return;
    }

    state.authenticated = payload.authenticated;
    state.roles = payload.resourceAccess?.[service]?.roles ?? [];
    state.token = payload.token;
    state.tokenParsed = payload.tokenParsed;
  },
};
