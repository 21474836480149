import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import(/* webpackChunkName: "index" */ '@/views/Home.vue'),
  },
  {
    path: '/clients',
    name: 'ClientsPage',
    component: () => import(/* webpackChunkName: "clients" */ '@/views/Clients.vue'),
  },
  {
    path: '/requests',
    name: 'RequestsPage',
    component: () => import(/* webpackChunkName: "requests" */ '@/views/Requests.vue'),
  },
  {
    path: '/emails',
    component: () => import(/* webpackChunkName: "emails" */ '@/views/Emails/Layout.vue'),
    redirect() {
      return {
        name: 'EmailsPage',
      };
    },
    children: [
      {
        path: 'templates',
        name: 'TemplatesPage',
        component: () => import(/* webpackChunkName: "emails" */ '@/views/Emails/Templates.vue'),
      },
      {
        path: '/',
        name: 'EmailsPage',
        component: () => import(/* webpackChunkName: "emails" */ '@/views/Emails/Emails.vue'),
      },
    ],
  },
  {
    path: '/messages',
    name: 'MessagesPage',
    component: () => import(/* webpackChunkName: "messages" */ '@/views/Messages.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
