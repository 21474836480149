import 'mutationobserver-shim';
import Vue from 'vue';
import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import './plugins/bootstrap-vue';

import App from './App.vue';
import router from './router';
import {
  store,
  AuthActionTypes,
} from './store';
import initAxios from './plugins/axios';
import { formatDate } from './filters';
import { constantsMixin } from './mixins';

Vue.config.productionTip = false;

Vue.use(VueKeyCloak, {
  config: {
    url: process.env.VUE_APP_SSO_URL,
    realm: process.env.VUE_APP_SSO_REALM,
    clientId: process.env.VUE_APP_SSO_CLIENT_PUBLIC,
  },
  init: {
    onLoad: 'login-required',
  },
  onReady: async keycloak => {
    window.$keycloak = keycloak;

    await store.dispatch(`auth/${AuthActionTypes.INIT}`, keycloak);

    initAxios(keycloak);
    initApp();
  },
});

function initApp() {
  Vue.filter('formatDate', formatDate);

  Vue.mixin(constantsMixin);

  Vue.prototype.$clone = function(obj) {
    return JSON.parse(JSON.stringify(obj));
  };

  new Vue({
    store,
    router,
    render: h => h(App),
  }).$mount('#app');
}
