import { AuthMutationTypes } from './mutations';

export const AuthActionTypes = {
  INIT: 'INIT',
};

export const actions = {
  [AuthActionTypes.INIT]({ commit }, keycloakInstance) {
    commit(AuthMutationTypes.INIT, keycloakInstance);
  },
};
